











import LayoutPage from '@/layouts/LayoutPage.vue';
import Vue, { VueConstructor } from 'vue';
import { accountingRouteShow } from '@/views/accounting/routes';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'Accounting',

  components: {
    LayoutPage,
  },

  data() {
    return {
      accountingRouteShow,
    };
  },
});
